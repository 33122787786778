.App {
  text-align: center;
}

html{
  background-color:#2F2E2E;
}
.logo{
  height:5vh;
  margin:1rem 0rem;
}
.App-logo {
  height: 30vmin;
  position:absolute;
  margin:auto;
  top:0;
  right:0;
  left:0;
  bottom:0;
  transform: translate(-50%,-50%);
  pointer-events: none;
  animation:App-logo-spin;
}
.bg-container{
  position:relative;
  height:fit-content;
  width: fit-content;
}
.icon-container, .icon-container a{
  margin:0;
  padding:0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon{
  height:1rem;
  margin:0rem 2.5px;
}
.icon:hover{
  color:blue;
}
.background-img{
  height:50vh;
  margin-bottom:0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #2F2E2E;
  max-width:100vw;
  padding:0vw 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height:100vh;
}

footer{
  position:fixed;
  bottom: 0%;
  min-width:100vw;
  height:10vh;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#ton{
  margin-bottom:1rem;
  margin-top:0.2rem;
  font-size: 0.8rem;
  font-weight: 300;
}
#notice{
  font-size: 0.7rem;
}
.bientot{
  margin:0rem 0rem 1.5rem 0rem;
  font-size:0.8rem;
}
footer{
  width:50%;
  height:auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom:0.8em;
}
footer p{
  margin: 0.2rem 0rem 0rem 0rem;
  color:white;
  font-size: 0.4rem;

}
#facebookLink{
  color:white;
  font-weight: bold;
}
footer a{
  text-decoration: none;
  font-weight: bold;
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
